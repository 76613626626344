//
// Tabs
.nav-tabs {
  .nav-item {
    margin-right: 0.5em;
    &:last-child {
      margin-right: 0;
    }
  }

  .nav-link {
    background: $gray-300;
    color: $body-color;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    &:hover {
      cursor: initial;
    }
  }
}
