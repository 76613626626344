@font-face {
  font-family: ProximaNova;
  font-weight: normal;
  font-style: normal;
  src: url("/fonts/proximanova/proximanova-regular.otf") format("opentype");
}

@font-face {
  font-family: ProximaNova;
  font-weight: normal;
  font-style: italic;
  src: url("/fonts/proximanova/proximanova-regularit.otf") format("opentype");
}

@font-face {
  font-family: ProximaNova;
  font-weight: bold;
  src: url("/fonts/proximanova/proximanova-bold.otf") format("opentype");
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.font-size-1 {
  font-size: 1.1rem !important;
}
