.details-tab {
	background-color: #dddddd;
	box-shadow: 0px -10px 12px 0px rgba(0, 0, 0, 0.05);
	border-bottom: none !important;
}

.details-tab__selected {
	background-color: white !important;
}

.details-tab #num-bubble {
	background-color: #525252;
	opacity: 0.5;
}

.details-tab__selected #num-bubble {
	background-color: #67b5ff;
	opacity: 1;
}
