.expanded-details {
  width: 100%;
}

.expanded-details thead tr th {
  font-weight: bold;
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.expanded-details tbody tr {
  border-top: 1px solid #ccc;
}

.expanded-details tbody tr td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.expanded-details tbody tr td.expanded-details-label {
  text-align: right;
  padding-right: 10px;
  width: 40%;
  vertical-align: top;
  font-style: italic;
}
