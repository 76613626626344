#root {
  position: relative;
  top: 0;
  height: 100vh;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar,
.main-panel {
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

.main-panel {
  background: #f2f2f2;
  position: relative;
  float: right;
  min-height: 100%;
  width: calc(100% - #{$sidebar-width-mobile});
  @include media-breakpoint-up(lg) {
    width: calc(100% - #{$sidebar-width});
  }

  > .content {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    height: calc(100% - 48px);
  }
}

.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: $sidebar-width-mobile;
  @include media-breakpoint-up(lg) {
    width: $sidebar-width;
  }
}

.search-widgets {
  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  min-height: 10em;
  width: 25em;
}

.login-modal {
  width: 28em;
}

.modal-header {
  background-color: black;
  color: white;
}

.-odd div {
  background-color: #fff;
}

.-even div {
  background-color: #f2f2f2;
}

.dark-odd div {
  background-color: #f6f6f6;
}

.dark-even div {
  background-color: #eaeaea;
}

div.rt-td.-selected div {
  background-color: #6eaee3;
}

div.rt-td.-selected {
  background-color: #6eaee3;
}

div.rt-tr:hover .rt-td:not(.-selected) {
  background-color: #fcfcc7;
}

div.rt-tr:hover .rt-td:not(.-selected):not(.-no-highlight) div {
  background-color: #fcfcc7;
}

.rt-thead .rt-th .title {
  font-weight: normal;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rt-thead.-filters input.search-filter {
  background-image: url(/fa-search.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 20px;
  width: 100%;
}

.dropdown-menu > li > a:hover {
  background-color: #fcfbc9;
}

.dropdown-menu > div > ul > a:hover {
  background-color: #ffec92;
}

.dropdown-menu > .dropdown-item:hover {
  background-color: #ffec92;
}

.react-tabs__tab {
  background-color: #ddd;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #aaa;
}

.react-tabs__tab--selected {
  background-color: #fff;
  border: none;
  border-bottom: none !important;
}

.H_ib_body {
  background-color: transparent;
  padding: 0;
}

.H_ib_close > svg.H_icon {
  fill: #1f262a;
  margin-top: 0.25em;
  margin-right: 0.25em;
}

.H_ib_tail {
  display: none;
}

.intl-tel-input .country-list {
  z-index: 10 !important;
}

.Select-value-label,
.Select-option {
  white-space: pre-wrap;
}

.fixedpaginationtable.ReactTable {
  margin-bottom: 32px;
}

.fixedpaginationtable.ReactTable .pagination-bottom {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: #fff;
}

.fixedpaginationtable.ReactTable .rt-thead {
  /* Allows the header to display above the pagination controls */
  z-index: 3;
}

.customtable.ReactTable .rt-thead .rt-th.-sort-desc,
.customtable.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #2785a9;
}

.customtable.ReactTable .rt-thead .rt-th.-sort-asc,
.customtable.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #2785a9;
}

.customtable.ReactTable .rt-tbody {
  /* This rule is removed from .rt-tbody because we are using a
   * bootstrap tooltip in LocationTable that overlayed on the filter row.
   *   e.g. Hover over cell in the first row with a tooltip.
   *
   * With `overflow: auto`, the tool tip wouldn't have room to render
   * but with overflow unset (allowing overflow), the tooltip can appear
   *
   * This should allow us to overlay things like tooltips in customtable's now
   */
  overflow: unset;
}

/* Need to hide the tip of the locinfowindow in Google Maps,
 since Google Maps' InfoWindow comes with a tip */
.gm-style-iw-d .locinfowindow-tip {
  display: none;
}

/*
  H1-1732: on the datetime picker in our advanced search filter widgets, make
  the calendar and time buttons appear separate (the standard style has them as
  the same button even though they're actually different underneath). Add a
  hairline border between them and move the hover effect from the combined
  container onto the individual buttons.
*/
.rw-datetime-picker .rw-select-bordered > button {
  border: 0;
  position: relative;
  text-align: center;
  top: -1px;
  width: 32px;
  height: 32px;
}

.rw-datetime-picker .rw-select-bordered > button:hover {
  background-color: #e6e6e6;
}

.rw-datetime-picker .rw-select-bordered > button:first-of-type {
  border-right: 1px solid #e6e6e6;
}

.rw-datetime-picker .rw-select-bordered:hover,
.rw-select-bordered:active {
  background-color: rgba(0, 0, 0, 0);
}

.search-button {
  height: 42px;
}

.typeahead-menu-item .dropdown-item {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 1.43;
}

.location-type-custom-select .css-1hwfws3 {
  max-height: 30px;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
}

// todo this is not a good solution
// but can't get it to work via table-styles
.ReactTable .rt-tbody .rt-expandable {
  max-width: none !important;
}
